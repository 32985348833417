import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity", "subtotal", "nettotal"];
  static values = { price: Number };

  connect() {
    this.updateSubtotal();
  }

  updateSubtotal() {
    const discount = parseFloat(document.getElementById('discount').value);
    const quantity = parseInt(this.quantityTarget.value);
    const subtotal = this.priceValue * quantity;
    const discountAmount = subtotal * (discount / 100);
    const netTotal = subtotal - discountAmount;
    var vat_ttl = (subtotal * 0.20);
    var ttl = vat_ttl + netTotal;
    const totalInPence = Math.round(ttl * 100); // Convert total to pence
    const totalPricePenceInput = document.getElementById('total_price_pence');

    if (totalPricePenceInput) {
      totalPricePenceInput.value = totalInPence;
    }

    this.nettotalTarget.textContent = this.numberToCurrency(netTotal);
    this.subtotalTarget.textContent = this.numberToCurrency(subtotal);
   
    const messageDiv = document.getElementById('bookingMessage');
    if (quantity > 4) {
      console.log('Quantity is above 4!');
      messageDiv.style.display = 'block';
    } else {
      messageDiv.style.display = 'none';
    }

    const event = new CustomEvent('quantityChanged', { detail: { quantity }, bubbles: true });
    this.element.dispatchEvent(event);
  }

  numberToCurrency(number) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(number);
  }
}
