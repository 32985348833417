import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "firstName", "lastName", "address", "city", "county", "postcode", "firstName1", 
  "firstName2", "firstName3", "firstName4", "firstName5", "firstName6", "firstName7", "firstName8",
  "lastName1", "lastName2", "lastName3", "lastName4", "lastName5", "lastName6", "lastName7", 
  "lastName8", "buyerEmail", "email1", "email2", "email3", "email4", "email5", "email6", "email7", 
  "email8"]


  validate() {
    this.clearErrors();
    let valid = true;
    const emailSet = new Set();
    const emails = [this.email1Target.value];

    const validations = [
      { target: this.firstNameTarget, minLen: 3, message: "Buyer's first name must be at least 3 characters" },
      { target: this.lastNameTarget, minLen: 3, message: "Buyer's last name must be at least 3 characters" },
      { target: this.addressTarget, minLen: 5, message: "Buyer's address must be at least 5 characters" },
      { target: this.cityTarget, minLen: 3, message: "Buyer's city must be at least 3 characters" },
      { target: this.countyTarget, minLen: 3, message: "Buyer's county must be at least 3 characters" },
      { target: this.postcodeTarget, minLen: 3, message: "Buyer's postcode must be valid" },
      { target: this.firstName1Target, minLen: 3, message: "Attendee's first name must be at least 3 characters" },
      { target: this.lastName1Target, minLen: 3, message: "Attendee's last name must be at least 3 characters" },
      {
        target: this.buyerEmailTarget,
        validate: value => this.isValidEmail(value),
        message: "Buyer's email must be a valid email address"
      },
      {
        target: this.email1Target,
        validate: value => this.isValidEmail(value),
        message: "Attendee's email must be a valid email address"
      }
    ];

    for (let i = 2; i <= 8; i++) {
      const firstNameTargetName = `firstName${i}Target`;
      const lastNameTargetName = `lastName${i}Target`;
      const emailTargetName = `email${i}Target`;

      if (this.hasTarget(`firstName${i}`)) {
        validations.push({
          target: this[firstNameTargetName],
          minLen: 3,
          message: `Attendee's first name ${i} must be at least 3 characters`
        });
      }

      if (this.hasTarget(`lastName${i}`)) {
        validations.push({
          target: this[lastNameTargetName],
          minLen: 3,
          message: `Attendee's last name ${i} must be at least 3 characters`
        });
      }

      if (this.hasTarget(`email${i}`)) {
        const emailTarget = this[emailTargetName];
        validations.push({
          target: emailTarget,
          validate: value => {
            if (!this.isValidEmail(value)) return false;
            emails.push(value);
            return true;
          },
          message: `Attendee's email ${i} must be a valid email address`
        });
      }
    }

    validations.push({
      validate: () => {
        emails.forEach((email, index) => {
          if (emailSet.has(email)) {
            const emailTargetName = `email${index + 1}Target`;
            this.showError(this[emailTargetName], `The email ${email} is already used.`);
            valid = false;
          } else {
            emailSet.add(email);
          }
        });
        return true;
      },
      message: "All emails must be unique."
    });

    validations.forEach(({ target, minLen, validate, message }) => {
      if ((minLen && target.value.length < minLen) || (validate && !validate(target?.value))) {
        this.showError(target, message);
        valid = false;
      }
    });

    if (!valid) {
      // Set the active tab to attendees when validation fails
      const attendeesTab = document.getElementById('tab-attendees');
      const termsTab = document.getElementById('tab-terms');
      const confirmTab = document.getElementById('tab-confirm');

      if (attendeesTab) {
        attendeesTab.classList.replace('text-gray-500', 'text-gray-900');
        attendeesTab.classList.add('active-tab');
        attendeesTab.setAttribute('aria-current', 'page');
      }

      if (confirmTab) {
        confirmTab.classList.replace('text-gray-900', 'text-gray-500');
        confirmTab.classList.remove('active-tab');
        confirmTab.removeAttribute('aria-current');
      }

      // Make Terms and Confirm tabs unclickable
      termsTab.classList.add('disabled-tab');
      confirmTab.classList.add('disabled-tab');

      // Hide all tab content except attendees
      document.getElementById('content-attendees').style.display = 'block';
      document.getElementById('content-course').style.display = 'none';
      document.getElementById('content-terms').style.display = 'none';
      document.getElementById('content-confirm').style.display = 'none';
    }


    return valid;
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  hasTarget(targetName) {
    return this[`has${targetName.charAt(0).toUpperCase() + targetName.slice(1)}Target`];
  }

  clearErrors() {
    this.element.querySelectorAll('.error-message').forEach(el => {
      el.remove();
    });
  }

  showError(target, message) {
    let formGroup = target.closest('.input-group');

    let errorList = formGroup.querySelector(".error-messages");

    if (!errorList) {
        errorList = document.createElement("ul");
        errorList.classList.add("error-messages", "text-red-500", "font-bold", "pl-5", "mt-1", "w-full", "block");
        formGroup.appendChild(errorList);
    }

    let errorItem = document.createElement("li");
    errorItem.textContent = message;  // Add the error message text

    errorList.appendChild(errorItem);
  }

  submit(event) {
    console.log('I am in submit');
    event.preventDefault(); 
    event.stopPropagation();
    if (this.validate()) {
      console.log('this is in the validation here')
      const form = this.formTarget;
      
    fetch(form.action, {
      method: "POST",
      body: new FormData(form),
      headers: {
        "Accept": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
    })
     .then(response => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then(data => {
        console.log('Unique Log Before Modal: Data Received:', data);
        this.showModal();
        document.getElementById('confirmation-number').value = data.confirmation_number;
        console.log('Unique Log after Modal: Data Received');
      })
      .catch(error => {
        this.showFlashMessage('Payment failed', 'alert');
        console.error("Error:", error);
      });
    }
  }

  showFlashMessage(message, type) {
    const flashMessages = document.getElementById('flash-messages');
    if (flashMessages) {
      const flashClass = type === 'notice' ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800';
      flashMessages.innerHTML = `
        <div class="${flashClass} p-4 mb-4 text-sm rounded">
          ${message}
        </div>
      `;
    } else {
      console.error('Flash message container not found');
    }
  }

  showModal() {
    const modal = document.getElementById('response-modal');
    const closeModal = document.getElementsByClassName('close')[0];

    modal.style.display = 'block';

    closeModal.onclick = function() {
      modal.style.display = 'none';
    }

    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    }
  }

}



